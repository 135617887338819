import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Product } from 'Common/types';
import ProductBadge from '../../ProductBadge';
import ProductInput from '../../ProductInput';

export default function ProductTableMobile({
    addToCartLabel,
    className,
    gtmListValue,
    isLoading,
    isUserAuthenticated,
    statusTooltip,
    onAddItemToCart,
    productInfo,
    specsTableBreakpoint,
    tableData,
    totalQty = 0,
}) {
    return (
        <div className={cx('ProductTableMobile Table', className)}>
            <table className="table table-sm">
                <thead>
                    <tr>
                        {tableData.columns.map((column, index) => (
                            <th key={column.name} scope={column.name === 'Product' ? 'row' : "col align-top"}>
                                {index > 0 ? <ProductBadge badges={column.badges} noTopMargin /> : null}
                                <span className={cx('subheader', index === 0 ? 'd-block' : null)}>{column.name}</span>
                                {index > 0 && onAddItemToCart ? (
                                    <ProductInput
                                        addToCartLabel={addToCartLabel}
                                        currentChildren={[{}]}
                                        currentItemStatus={{ hasStock: column.hasStock, code: column.name }}
                                        disabled={isLoading || !column.hasStock}
                                        gtmListValue={gtmListValue}
                                        isUserAuthenticated={isUserAuthenticated}
                                        onAddToCart={onAddItemToCart}
                                        productInfo={productInfo}
                                        statusTooltip={statusTooltip ? 'bottom' : null}
                                        quantity={1}
                                        totalQty={totalQty}
                                        itemCodes={[column.name]}
                                    />
                                ) : null}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableData.rows.map((row) => (
                        <React.Fragment key={row.label}>
                            <tr>
                                <th scope="row">
                                    <p className="paragraph-2">{row.label}</p>
                                </th>
                                {row.values.map((cell) => (
                                    <td key={cell.id}>
                                        {cell.values.map((cellVal, index) => (
                                            <p key={cellVal} className="paragraph-2 d-inline">
                                                {cellVal}
                                                {index < cell.values.length - 1 ? ', ' : null}
                                            </p>
                                        ))}
                                    </td>
                                ))}
                            </tr>
                        </React.Fragment>
                    ))}
                    {specsTableBreakpoint && tableData.rows.length > specsTableBreakpoint && onAddItemToCart ? (
                        <tr className="border-bottom-0">
                            <th scope="row"></th>
                            {tableData.columns.slice(1).map((column) => (
                                <td key={column.name} scope="col align-top">
                                    <ProductInput
                                        addToCartLabel={addToCartLabel}
                                        currentChildren={[{}]}
                                        currentItemStatus={{ hasStock: column.hasStock, code: column.name }}
                                        disabled={isLoading || !column.hasStock}
                                        gtmListValue={gtmListValue}
                                        isUserAuthenticated={isUserAuthenticated}
                                        onAddToCart={onAddItemToCart}
                                        productInfo={productInfo}
                                        statusTooltip={statusTooltip ? 'bottom' : null}
                                        quantity={1}
                                        totalQty={totalQty}
                                    />
                                </td>
                            ))}
                        </tr>
                    ) : null}
                </tbody>
            </table>
        </div>
    );
}

ProductTableMobile.propTypes = {
    addToCartLabel: px.string,
    className: px.string,
    gtmListValue: px.string,
    isLoading: px.bool,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    statusTooltip: px.oneOf([false, true, undefined, null, 'top', 'bottom']),
    onAddItemToCart: px.func,
    productInfo: Product,
    specsTableBreakpoint: px.number,
    tableData: px.shape({
        columns: px.arrayOf(
            px.shape({
                name: px.string,
                hasStock: px.bool,
            })
        ),
        rows: px.arrayOf(
            px.shape({
                values: px.arrayOf(
                    px.shape({
                        id: px.string,
                        values: px.arrayOf(px.string),
                    })
                ),
                label: px.string,
            })
        ),
    }),
    totalQty: px.number,
};
