import React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import { Carousel, CarouselItem, Modal, MediaConverter } from 'Common/components/ui';

const NOOP = () => {};

function ImageModalContent({ content, className } = {}) {
    const { type = 'image', src, selectedItem } = content || {};

    return type === 'spin' ? (
        <div className={cx(`${className}__Image Sirv`)} data-src={src} />
    ) : (
        <MediaConverter
            media={selectedItem}
            className={cx(`${className}__Image`)}
            renderVideoAsThumb={false}
            renderSpinAsThumb={false}
            autoPlayVideo
        />
    );
}

ImageModalContent.propTypes = {
    content: px.shape({
        type: px.string,
        src: px.string,
        showSlider: px.bool,
        items: px.arrayOf(px.object),
        isMobile: px.bool,
        selectedItem: px.object,
    }),
    className: px.string,
};

function ImageModalFooter({ isMobile, items, onSelectItem, showCarousel = false }) {
    return showCarousel ? (
        <Carousel
            items={items}
            onSelectItem={onSelectItem}
            itemProps={{ onClickGalleryImg: NOOP, showGalleryMobile: true, isMobile }}
            keyBy="Url"
            Item={CarouselItem}
            scrollToSelected
            animateScroll
        />
    ) : null;
}

ImageModalFooter.propTypes = {
    isMobile: px.bool,
    items: px.arrayOf(px.object),
    onSelectItem: px.func,
    showCarousel: px.bool,
};

export default function ImageModal({
    className,
    closeModal,
    isMobile,
    items,
    modalContent,
    modalSize,
    setModalContent,
    setShowModalSlider,
    showModal,
    showCarousel,
}) {
    const modalId = React.useRef(`ImageModal_${v4()}`);

    const onSelectModalItem = React.useCallback(
        (m) => setModalContent({ ...modalContent, src: m.Url, type: m.FileType, selectedItem: m }),
        [modalContent, setModalContent]
    );

    return (
        <Modal
            className={className}
            id={modalId.current}
            onClose={closeModal}
            showModal={showModal}
            modalSize={modalSize}
            body={<ImageModalContent content={modalContent} className={className} />}
            onOpen={setShowModalSlider}
            footer={
                <ImageModalFooter
                    items={items}
                    isMobile={isMobile}
                    onSelectItem={onSelectModalItem}
                    showCarousel={showCarousel}
                />
            }
        />
    );
}

ImageModal.propTypes = {
    className: px.string,
    closeModal: px.func,
    isMobile: px.bool,
    items: px.arrayOf(px.object),
    modalContent: px.object,
    modalSize: px.string,
    setModalContent: px.func,
    setShowModalSlider: px.func,
    showModal: px.bool,
    showCarousel: px.bool,
};
