import { Translation } from 'Common/components/localization';
import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Formatter } from 'Common/utils';
import { useViewport } from 'Common/hooks';
import { Grid, Header, Row, Cell } from 'Common/components/ui/Grid';
import ItemDetailsModal from './ItemDetailsModal';

export default function ProductChildTable({ disableItemDetails, productChildren }) {
    const viewport = useViewport();
    const isMobile = viewport.is.lt('lg');
    const quickViewRef = React.useRef(null);
    const [selectedVariant, setSelectedVariant] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);

    const isPriceExists = productChildren.filter((variation) => variation.Price.ListPrice.Amount > 0).length > 0;
    const closeModal = React.useCallback(() => {
        setShowModal(false);
        setSelectedVariant(null);
    }, []);

    React.useEffect(() => {
        if (selectedVariant && quickViewRef.current) {
            quickViewRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [selectedVariant]);

    const handleVariantSelection = React.useCallback(
        (child) => {
            if (child?.Code === selectedVariant?.Code) {
                setSelectedVariant(null);
                setShowModal(false)
            } else {
                setSelectedVariant(child);
                setShowModal(true);
            }
        },
        [selectedVariant]
    );

    return productChildren?.length ? (
        <div className={cx('ProductChildTable')}>
            <Grid className={disableItemDetails ? "ProductChildTable__Grid--QuickView" : "ProductChildTable__Grid"}>
                <Header>
                    {!isMobile ? (
                        <Cell className="text-left pl-4">
                            <Translation id="Commerce.Product.ChildrenTable.Code" />
                        </Cell>
                    ) : null}
                    <Cell className="text-center" width="4fr">
                        <Translation id="Commerce.Product.ChildrenTable.Description" />
                    </Cell>
                    {!isMobile ? (
                        <Cell className="text-center">
                            <Translation id="Commerce.Product.ChildrenTable.Price" />
                        </Cell>
                    ) : null}
                    {disableItemDetails ? null : (<Cell width="2rem" />)}
                    
                </Header>
                {productChildren.map((child) => {
                    const isOpen = selectedVariant === child.Code;

                    return [
                        <Row key={child.Code} onClick={disableItemDetails ? null : () => handleVariantSelection(child)}>
                            {!isMobile ? (
                                <Cell className={`${isOpen ? 'Grid__cell--selected' : ''} Grid__code text-left pl-4`}>
                                    {child.Code}
                                </Cell>
                            ) : null}
                            <Cell 
                                className={(`${isOpen ? 'Grid__cell--selected' : ''} Grid__name text-left`, 'text-truncate')}
                                title={child.DisplayName.length > 45 ? child.DisplayName : null}
                            >
                                {child.DisplayName}
                            </Cell>
                            {isPriceExists && !isMobile ? (
                                <Cell className={`${isOpen ? 'Grid__cell--selected' : ''} Grid__price text-center`}>
                                    {child.Price.ListPrice.Amount > 0
                                        ? Formatter.currency(
                                              child.Price.ListPrice.Amount,
                                              child.Price.ListPrice.Currency
                                          )
                                        : null}
                                </Cell>
                            ) : null}
                            {disableItemDetails ? null : (
                                <Cell className={`${isOpen ? 'Grid__cell--selected' : ''} Grid__dropdown text-center`}>
                                    <i className={cx('fas', `fa-chevron-${isOpen ? 'up' : 'down'}`)} />
                                </Cell>
                            )}
                            
                        </Row>,
                    ].filter(Boolean);
                })}
            </Grid>
            {showModal && !disableItemDetails ? (
                <ItemDetailsModal 
                    selectedChild={selectedVariant} 
                    closeModal={closeModal}
                    showModal={showModal}
                    setShowModal={setShowModal} 
                />
            ) : null}
        </div>
    ) : null;
}

ProductChildTable.propTypes = {
    disableItemDetails: px.bool,
    productChildren: px.arrayOf(
        px.object
    ),
};
