import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import * as PRODUCT from '~config/product';
import { GTM as gtmUtil } from 'Common/utils';
import * as GTM from 'Common/constants/gtm';
import { Translation } from 'Common/components/localization';
import ProductOptions from './ProductOptions';
import ProductPrice from './ProductPrice';
import ChildDetail from './ChildDetail';
import ProductInput from './ProductInput';
import ProductRating from './ProductRating';
import ProductChildTable from './ProductChildTable';
import ProductShare from './ProductShare';
import { HTMLRender } from '../ui';

export default function ProductDetails({
    checkoutPageLink,
    className,
    clearFacets,
    currentChildren,
    currentHazmat,
    currentItemStatus,
    currentOversize,
    desktopBreakpoint = 'md',
    disabledFacets,
    facets,
    gtmListValue,
    includeDescription,
    isQuickView,
    isUserAuthenticated,
    itemStatusTooltip,
    Links,
    onAddItemToCart,
    onFacetSelect,
    onQuantityChange,
    onSelectChild,
    pageId,
    priceInfo,
    productChildren,
    productInfo,
    productRecommendationId,
    quantity,
    renderedInQuickView = false,
    returnPolicyUrl = null,
    reviewProviderEntityId,
    selectedChild,
    siteId,
    totalQty = 0,
    useSocialShare,
    writeReviewAnchor,
}) {
    const currentChildRef = React.useRef();
    const showSpecificationsLink = React.useMemo(
        () =>
            productInfo?.Options?.SpecificationsLinkLabel &&
            productInfo.Options.ShowSpecifications?.toLowerCase() === 'true',
        [productInfo]
    );

    const displayOnly = React.useMemo(() => productInfo?.StockStatus === 'DisplayOnly', [productInfo]);

    const onShowSpecifications = React.useCallback(() => {
        const tabContents = document.getElementById(`${pageId}_content`).children;
        const contentTabs = document.getElementById(`${pageId}_tabs`).children;

        for (let i = 0, l = contentTabs.length; i < l; i++) {
            const tabContent = tabContents[i];
            const contentTab = contentTabs[i];

            if (tabContent.id.includes('Specifications')) {
                if (tabContent.classList.contains('active') && tabContent.classList.contains('show')) break;

                const tabLink = contentTab.getElementsByClassName('nav-link');

                tabLink.item(0).classList.add('active');
                tabContent.classList.add('active');
                tabContent.classList.add('show');
            } else if (tabContent.classList.contains('active') || tabContent.classList.contains('show')) {
                const tabLink = contentTab.getElementsByClassName('nav-link');

                tabLink.item(0).classList.remove('active');
                tabContent.classList.remove('active');
                tabContent.classList.remove('show');
            }
        }
    }, [pageId]);

    const itemCodes = React.useMemo(() => currentChildren?.map((c) => c.Code), [currentChildren]);

    React.useEffect(() => {
        if (currentChildren.length === 1) {
            const currentChildCode = currentChildren[0].Code;

            if (currentChildCode != currentChildRef.current) {
                const gtmPayload = gtmUtil.mapEntityToImpressions(currentChildren, GTM.TAGS.PDP);

                if (gtmPayload) gtmUtil.updateDataLayer(gtmPayload);
                currentChildRef.current = currentChildCode;
            }
        }
    }, [currentChildren]);

    return productInfo ? (
        <div className={cx('ProductDetails text-center text-md-left px-0', className)}>
            {!renderedInQuickView ? (
                <ProductRating
                    className={cx('container', showSpecificationsLink ? 'mb-3' : null)}
                    rating={productInfo.Rating}
                    reviewCount={productInfo.ReviewsCount}
                    writeReviewAnchor={writeReviewAnchor}
                    isDiscontinued={productInfo.ItemStatus === 'Discontinued'}
                    reviewProviderEntityId={reviewProviderEntityId}
                />
            ) : null}
            {!renderedInQuickView && showSpecificationsLink ? (
                <a className="subheader" onClick={onShowSpecifications} href={`#${pageId}_tabs`}>
                    {productInfo.Options.SpecificationsLinkLabel}
                </a>
            ) : null}
            {!displayOnly && productInfo.TypeId === 'Product' && productChildren && productChildren.length ? (
                <ProductOptions
                    clearFacets={clearFacets}
                    currentChildren={currentChildren}
                    disabledFacets={disabledFacets}
                    facets={facets}
                    onFacetSelect={onFacetSelect}
                    onSelectChild={onSelectChild}
                    productChildren={productChildren}
                    productCode={productInfo.Code}
                    selectedChild={selectedChild}
                />
            ) : null}
            {displayOnly &&
            productInfo.TypeId === 'Product' &&
            productChildren &&
            productChildren.length &&
            PRODUCT.SHOW_CHILD_TABLE ? (
                <ProductChildTable
                    clearFacets={clearFacets}
                    onSelectChild={onSelectChild}
                    productChildren={productChildren}
                    selectedChild={selectedChild}
                    disableItemDetails={isQuickView}
                />
            ) : null}

            <div className="row my-3">
                {PRODUCT.SHOW_CHILD_TABLE ? null : (
                    <h6 className="col-12 col-md-6 pr-0">
                        <ProductPrice priceInfo={priceInfo} productInfo={productInfo} quantity={quantity} />
                    </h6>
                )}

                {displayOnly ? null : (
                    <ChildDetail
                        className={`col-12 col-${desktopBreakpoint}-6 p-0 mb-0`}
                        currentItemStatus={currentItemStatus}
                        productCode={productInfo.Code}
                        productTypeId={productInfo.TypeId}
                    />
                )}
            </div>
            {!renderedInQuickView && productInfo.Description && includeDescription ? (
                <div className="ProductDetails__description row my-3">
                    <div>
                        <HTMLRender content={productInfo.Description} />
                    </div>
                </div>
            ) : null}
            {currentOversize?.description ? (
                <div className="container pt-3 oversize pl-0">
                    <p className="paragraph-3 mb-0">{currentOversize.description}</p>
                </div>
            ) : null}
            {currentHazmat?.description ? (
                <div className="container hazmat pl-0">
                    <p className="paragraph-3 mb-0">{currentHazmat.description}</p>
                </div>
            ) : null}
            {productInfo.ItemStatus === 'Discontinued' || displayOnly ? null : (
                <ProductInput
                    isUserAuthenticated={isUserAuthenticated}
                    onQuantityChange={onQuantityChange}
                    quantity={quantity}
                    checkoutPageLink={checkoutPageLink}
                    currentChildren={currentChildren}
                    onAddToCart={onAddItemToCart}
                    currentItemStatus={currentItemStatus}
                    productInfo={productInfo}
                    gtmListValue={gtmListValue}
                    statusTooltip={itemStatusTooltip}
                    productRecommendationId={productRecommendationId}
                    itemCodes={itemCodes}
                    totalQty={totalQty}
                    skipGtmImpression
                />
            )}
            <div className="container">{Links}</div>
            <div className="ProductDetails__sharepolicy container d-flex">
                {useSocialShare ? <ProductShare siteId={siteId} /> : null}
                {returnPolicyUrl ? (
                    <div className="col">
                        <a href={returnPolicyUrl}>
                            <Translation id="Commerce.Order.Return.ReturnPolicy" />
                        </a>
                    </div>
                ) : null}
            </div>
        </div>
    ) : null;
}

ProductDetails.propTypes = {
    checkoutPageLink: px.string,
    className: px.string,
    clearFacets: px.func,
    currentChildren: px.arrayOf(px.object),
    currentHazmat: px.shape({
        code: px.string,
        description: px.string,
    }),
    currentItemStatus: px.shape({ hasStock: px.bool, statusDisplayName: px.string, code: px.string }),
    currentOversize: px.object,
    desktopBreakpoint: px.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    disabledFacets: px.object,
    facets: px.arrayOf(types.Facet),
    gtmListValue: px.string,
    includeDescription: px.bool,
    isQuickView: px.bool,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    itemStatusTooltip: px.oneOfType([px.string, px.bool]),
    Links: px.node,
    onAddItemToCart: px.func,
    onFacetSelect: px.func,
    onQuantityChange: px.func,
    onSelectChild: px.func,
    pageId: px.string,
    priceInfo: px.object,
    productChildren: px.arrayOf(px.object),
    productInfo: px.object,
    productRecommendationId: px.string,
    quantity: px.oneOfType([px.string, px.number]),
    renderedInQuickView: px.bool,
    reviewProviderEntityId: px.string,
    returnPolicyUrl: px.string,
    selectedChild: px.object,
    siteId: px.string,
    totalQty: px.number,
    useSocialShare: px.bool,
    writeReviewAnchor: px.string,
};
