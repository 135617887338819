import * as React from 'react';
import px from 'prop-types';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import { ProductGallery as Gallery } from 'Common/components/product';
import { product } from '~features';

export default function ProductGallery({ useCarousel = true, onlyProductMedia = false, useImageModal = true, ...props }) {
    const media = useSelector(product.selectors.getMedia);
    const productMedia = useSelector(product.selectors.getProductMedia);
    const productMainMedia = useSelector(product.selectors.getProductMainMedia);
    const mainMedia = useSelector(product.selectors.getMainMedia);
    const onSelectImage = useAction(product.actions.selectSelectedMedia);

    
    return (
        <Gallery
            {...props}
            media={onlyProductMedia ? productMedia : media}
            mainMedia={onlyProductMedia ? productMainMedia : mainMedia}
            onSelectImage={onSelectImage}
            useCarousel={useCarousel}
            useImageModal={useImageModal}
        />
    );
}

ProductGallery.propTypes = {
    useCarousel: px.oneOf([true, false, 'react', 'react-paged', 'slick']),
    onlyProductMedia: px.bool,
    useImageModal: px.bool
};
