import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import * as types from 'Common/types';
import { Dropdown, FilterDropdown } from '../ui';
import { Translation } from '../localization';
import { useTranslation } from '../../hooks';

const PAGE_OPTIONS = [{ Value: 2 }, { Value: 10 }, { Value: 25 }, { Value: 50 }, { Value: 0 }];

const SORT_OPTIONS = [{ Value: 1, Text: 'A to Z' }];

function convertPaginationOptions(options = [], pageInfo = {}) {
    return options?.map((p) => ({
        value: p.Value === 0 ? pageInfo.total : p.Value,
        label: p.label || p.Text || p.value || p.Value,
    }));
}

function convertSortOptions(options = []) {
    return options.map((p) => ({
        value: p.value || p.Value,
        label: p.label || p.Text || p.value || p.Value,
    }));
}

export default function CatalogFilters({
    className,
    facets = [],
    hidePagination = false,
    hideSort = false,
    includeCloseIcon = false,
    onUpdateFacet,
    onUpdatePageSize,
    onUpdateSort,
    pageInfo = {},
    paginationOptions = PAGE_OPTIONS,
    showingCtaBlock,
    sortOptions = SORT_OPTIONS,
    startExpanded = false,
    viewport
}) {

    const [isOpen, setIsOpen] = React.useState(false);
    const [isFacetOpen, setIsFacetOpen] = React.useState(false)
    const [hasFacetsDropdown, setHasFacetsDropdown] = React.useState(false)
    const clickedRef = React.useRef(false);

    const toggleOpen = React.useCallback(() => {
        clickedRef.current = !isOpen;
        setIsOpen((val) => !val);
    }, [isOpen]);

    const toggleFacetOpen = React.useCallback(() => {
        clickedRef.current = !isFacetOpen;
        setIsFacetOpen((val) => !val);
    }, [isFacetOpen])

    const sortBy = useTranslation('Commerce.CatalogNode.Filters.SortBy');
    const showingItems = useTranslation('Commerce.CatalogNode.Filters.ShowingItems', {
        ...pageInfo,
        from: 1,
        to: showingCtaBlock.toLowerCase() === 'true' && pageInfo.count < pageInfo.total ? pageInfo.to - 1 : pageInfo.to,
    });

    const onSelectFacet = React.useCallback(
        (facet) => (value) =>
            onUpdateFacet
                ? onUpdateFacet(
                      facet,
                      facet.Values.find((v) => v.Value === value)
                  )
                : null,
        [onUpdateFacet]
    );

    const pageOpts = React.useMemo(
        () => convertPaginationOptions(paginationOptions, pageInfo),
        [paginationOptions, pageInfo]
    );

    const sortOpts = React.useMemo(() => convertSortOptions(sortOptions), [sortOptions]);
    const curPageOpt = React.useMemo(() => pageOpts.find((p) => p.value === pageInfo.pageSize), [pageOpts, pageInfo]);

   React.useEffect(() => {
        if (viewport.is.lt('xl') && (facets?.length >= 5)) {
            setHasFacetsDropdown(true)
        }; 
    }, [viewport, facets.length])
         
    React.useEffect(() => {
        function onWindowClick() {
            if ((isFacetOpen || isOpen) && !clickedRef.current && !startExpanded) {
                setIsOpen(false);
                setIsFacetOpen(false)
            } 
            clickedRef.current = false;
        }

        window.addEventListener('click', onWindowClick);
        return () => window.removeEventListener('click', onWindowClick);
    }, [isOpen, isFacetOpen, startExpanded]);

    return (
        <div className={cx('CatalogFilters', className)}>
            <section className="container">
                <div className="CatalogFilters__info">
                    <span className="CatalogFilters__title text-nowrap">
                        {pageInfo.count ? showingItems : <Translation id="Commerce.CatalogNode.Filters.NoItems" />}
                    </span>
                </div>
                <div className="CatalogFilters__filters">
                    
                    {hasFacetsDropdown ? (
                        <>
                            <Dropdown
                                    className="FilterDropdown mx-1"
                                    selectedValue={<Translation id="Commerce.CatalogNode.Filters.FilterProducts" />}
                                    hasFacetsDropdown={true}
                                    isOpen={isOpen}
                                    toggleOpen={toggleOpen}
                                    icon={<i className={`fa fa-chevron-${isOpen ? 'up' : 'down'}`} aria-hidden="true" />}
                            >
                                <div onClick={toggleFacetOpen}>
                                    {facets.map((facet) => (
                                        <FilterDropdown
                                            className=""
                                            key={facet.DisplayName}
                                            title={facet.DisplayName}
                                            selectedValues={facet.Values.filter((v) => v.Selected).map((v) => v.Value)}
                                            options={facet.Values.map((v) => ({ label: v.Label, value: v.Value }))}
                                            onSelect={onSelectFacet(facet)}
                                            ctrlType={facet.SelectorType}
                                            autoClose={false}
                                            startExpanded={startExpanded}
                                            includeCloseIcon={includeCloseIcon}
                                            hasFacetsDropdown={true}
                                        />
                                    ))}
                                </div>
                            </Dropdown>
                        </>
                    ) : (
                        <>
                            <span className="CatalogFilters__title d-none">
                                <Translation id="Commerce.CatalogNode.Filters.FilterProducts" />
                            </span>
                            {facets.map((facet) => (
                                <FilterDropdown
                                    className="mx-1"
                                    key={facet.DisplayName}
                                    title={facet.DisplayName}
                                    selectedValues={facet.Values.filter((v) => v.Selected).map((v) => v.Value)}
                                    options={facet.Values.map((v) => ({ label: v.Label, value: v.Value }))}
                                    onSelect={onSelectFacet(facet)}
                                    ctrlType={facet.SelectorType}
                                    autoClose={false}
                                    startExpanded={startExpanded}
                                    includeCloseIcon={includeCloseIcon}
                                />
                            ))}
                        </>
                    )}
                </div>
                {!hidePagination || !hideSort ? (
                    <div className="CatalogFilters__paging">
                        {onUpdatePageSize && !hidePagination ? (
                            <FilterDropdown
                                className="mx-1"
                                anchor="left"
                                title={
                                    <Translation
                                        id="Commerce.CatalogNode.Filters.ShowItems"
                                        params={{ items: curPageOpt?.label || pageInfo.pageSize }}
                                    />
                                }
                                selectedValues={[pageInfo.pageSize]}
                                options={pageOpts}
                                onSelect={onUpdatePageSize}
                                includeCloseIcon={includeCloseIcon}
                            />
                        ) : null}
                        {onUpdateSort && !hideSort ? (
                            <FilterDropdown
                                className="mx-1"
                                anchor="left"
                                title={sortOpts.find((o) => o.value === pageInfo.sortValue)?.label ?? sortBy}
                                selectedValues={[pageInfo.sortValue]}
                                options={sortOpts}
                                onSelect={onUpdateSort}
                                includeCloseIcon={includeCloseIcon}
                            />
                        ) : null}
                    </div>
                ) : null}
            </section>
        </div>
    );
}

CatalogFilters.propTypes = {
    className: px.string,
    facets: px.arrayOf(types.Facet),
    hidePagination: px.bool,
    hideSort: px.bool,
    includeCloseIcon: px.bool,
    onUpdateFacet: px.func,
    onUpdateSort: px.func,
    onUpdatePageSize: px.func,
    pageInfo: types.PageInfo,
    paginationOptions: px.arrayOf(
        px.shape({
            Text: px.string,
            Value: px.number,
        })
    ),
    sortOptions: px.arrayOf(
        px.shape({
            Text: px.string,
            Value: px.number,
        })
    ),
    showingCtaBlock: px.string,
    startExpanded: px.bool,
    viewport: px.object
};
