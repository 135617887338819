import * as React from 'react';
import Slider from 'react-slick';
import cx from 'classnames';
import px from 'prop-types';
import * as PRODUCT from '~config/product';
import { useViewport, useTranslator } from 'Common/hooks';
import { ArrowButton, Carousel, CarouselItem, ImageModal, MediaConverter, PagedCarousel } from 'Common/components/ui';

const getSliderSettings = (slidesToShow, useImageModal = true) => {
    return {
        slidesToShow,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        speed: 500,
        focusOnSelect: false,
        centerMode: false,
        prevArrow: <ArrowButton direction="left" className="slick-prev" />,
        nextArrow: <ArrowButton direction="right" className="slick-next" />,
        swipeToScroll: true,
        swipe: true,
        draggable: true,
        cssEase: 'ease',
        useCSS: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: useImageModal ? 3 : 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: useImageModal ? 2 : 3,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: useImageModal ? 1 : 2,
                },
            },
        ],
    };
};

export default function ProductGallery({
    allowImageZoom = true,
    children,
    className,
    desktopBreakpoint = 'md',
    largeCarouselImages = false,
    mainMedia,
    media = [],
    noGallery = false,
    onMainImageClick,
    onSelectImage,
    showGalleryMobile = false,
    slidesToShow = 4, // slick only
    useCarousel = false,
    useCarouselMobile = false,
    useImageModal = true,
    useModalCarousel = true,
}) {
    const viewport = useViewport();
    const isMobile = React.useMemo(() => viewport.is.lt(desktopBreakpoint), [viewport, desktopBreakpoint]);

    const thumbTranslator = useTranslator('Commerce.Product.Gallery.SelectThumb.Label');

    const [modalContent, setModalContent] = React.useState(null);
    const [showModalCarousel, setShowModalCarousel] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);

    const items = React.useMemo(() => {
        return media?.filter((m) => m?.Url && ((m.MediaType === 'video' && m.ThumbnailUrl) || m.MediaType === 'image'));
    }, [media]);

    const primaryItem = React.useMemo(() => mainMedia ?? items[0], [mainMedia, items]);

    const slickSettings = React.useMemo(() => {
        return getSliderSettings(slidesToShow, useImageModal);
    }, [slidesToShow, useImageModal]);

    const onOpenModal = React.useCallback(
        (selectedMedia) => () => {
            if (allowImageZoom && selectedMedia?.MediaType === 'image') {
                setModalContent({
                    type: selectedMedia.FileType ?? '',
                    src: selectedMedia.Url ?? '',
                    isMobile: isMobile ?? false,
                    selectedItem: selectedMedia,
                });
                setShowModal(true);
            }
        },
        [allowImageZoom, isMobile]
    );

    const onClickGalleryItem = React.useCallback(
        (selectedMedia) =>
            !showGalleryMobile && (isMobile || useCarousel === 'react-paged') && useImageModal
                ? null
                : () => {
                      onSelectImage?.(selectedMedia);
                  },
        [showGalleryMobile, isMobile, useCarousel, useImageModal, onSelectImage]
    );

    const onClickGalleryImg = React.useCallback(
        (selectedMedia) =>
            (isMobile || useCarousel === 'react-paged') && !showGalleryMobile ? onOpenModal(selectedMedia) : null,
        [isMobile, useCarousel, onOpenModal, showGalleryMobile]
    );

    const closeModal = React.useCallback(() => setShowModal(false), []);

    const onSelectItem = React.useCallback((m) => onClickGalleryItem(m)?.(), [onClickGalleryItem]);

    const setShowModalSlider = React.useCallback(() => setShowModalCarousel(true), []);

    const noGalleryMobile = React.useMemo(
        () => !useCarousel || showGalleryMobile || !isMobile,
        [useCarousel, showGalleryMobile, isMobile]
    );

    const carousel = React.useMemo(() => {
        if (!isMobile) {
            return useCarousel;
        } else if (useCarouselMobile) {
            return useCarouselMobile;
        } else {
            return useCarousel;
        }
    }, [isMobile, useCarousel, useCarouselMobile]);

    const imageResizeSearchQuery = React.useMemo(() => {
        const isSmallThumb = !largeCarouselImages && (showGalleryMobile || !isMobile);

        return [
            `format=png`,
            isSmallThumb ? 'width=90&height=70' : 'height=220&width=440',
            PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? 'transBg=true' : '',
        ]
            .filter(Boolean)
            .join('&');
    }, [isMobile, showGalleryMobile, largeCarouselImages]);

    const imageResizeSearchQueryNoWidth = React.useMemo(() => {
        const isSmallThumb = !largeCarouselImages && (showGalleryMobile || !isMobile);

        return [
            `format=png`,
            isSmallThumb ? 'height=70' : 'height=220',
            PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? 'transBg=true' : '',
        ]
            .filter(Boolean)
            .join('&');
    }, [isMobile, showGalleryMobile, largeCarouselImages]);

    return (
        <div className={cx('ProductGallery', className)}>
            {/* Main image (or video) */}
            {(!noGallery && noGalleryMobile) || !useImageModal ? (
                <MediaConverter
                    media={primaryItem}
                    className="fit-image-main w-100"
                    imageResizeSearchQuery={`format=png&height=450&width=550${
                        PRODUCT.TRANSFORM_PRODUCT_IMAGE_BACKGROUND ? '&transBg=true' : ''
                    }`}
                    autoPlayVideo
                    renderSpinAsThumb={!carousel}
                    onSelectPhoto={onMainImageClick ?? onOpenModal(primaryItem)}
                    onSelectSpin={onMainImageClick ?? onOpenModal(primaryItem)}
                />
            ) : null}

            {carousel === true || carousel === 'slick' ? (
                <Slider {...slickSettings}>
                    {items.map((m) =>
                        m?.Url && ((m.MediaType === 'video' && m.ThumbnailUrl) || m.MediaType === 'image') ? (
                            <div key={m.Url}>
                                <button
                                    onClick={onClickGalleryItem(m)}
                                    className={cx('btn', m?.Url === mainMedia?.Url && 'selected')}
                                    aria-label={thumbTranslator(m)}
                                >
                                    <MediaConverter
                                        media={m}
                                        onSelectPhoto={onClickGalleryImg(m)}
                                        onSelectSpin={onClickGalleryImg(m)}
                                        className="fit-image-nav img-fluid"
                                        imageResizeSearchQuery={imageResizeSearchQuery}
                                        renderVideoAsThumb={!isMobile || showGalleryMobile}
                                        renderSpinAsThumb
                                        autoPlayVideo
                                    />
                                </button>
                            </div>
                        ) : null
                    )}
                </Slider>
            ) : carousel === 'react' ? (
                <Carousel
                    items={items}
                    onSelectItem={onSelectItem}
                    itemProps={{
                        onClickGalleryImg,
                        showGalleryMobile,
                        isMobile,
                        imageResizeSearchQuery,
                    }}
                    keyBy="Url"
                    Item={CarouselItem}
                    scrollToSelected
                    animateScroll
                />
            ) : carousel === 'react-paged' ? (
                <PagedCarousel gap={15} onSelectItem={onSelectItem} maxVisibleItems={3}>
                    {items.map((item, i) => (
                        <CarouselItem
                            onClickGalleryImg={onClickGalleryImg}
                            showGalleryMobile={showGalleryMobile}
                            isMobile={isMobile}
                            imageResizeSearchQuery={imageResizeSearchQueryNoWidth}
                            item={item}
                            key={i}
                        />
                    ))}
                </PagedCarousel>
            ) : null}

            {/* Modal handling */}
            {useImageModal && (
                <>
                    <ImageModal
                        className="ProductGallery__Modal"
                        closeModal={closeModal}
                        isMobile={isMobile}
                        items={items}
                        modalContent={modalContent}
                        modalSize="xl"
                        setModalContent={setModalContent}
                        setShowModalSlider={setShowModalSlider}
                        showModal={showModal}
                        showCarousel={useModalCarousel && showModalCarousel}
                    />
                    {children}
                </>
            )}
        </div>
    );
}

ProductGallery.propTypes = {
    allowImageZoom: px.bool,
    children: px.node,
    className: px.string,
    desktopBreakpoint: px.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    largeCarouselImages: px.bool,
    mainMedia: px.shape({
        MediaType: px.string,
        MediaGroup: px.string,
        Url: px.string,
        Thumbnail: px.string,
        FileType: px.string,
        Order: px.number,
        IsDefault: px.bool,
    }),
    media: px.arrayOf(
        px.shape({
            MediaType: px.string,
            MediaGroup: px.string,
            Url: px.string,
            Thumbnail: px.string,
            FileType: px.string,
            Order: px.number,
            IsDefault: px.bool,
        })
    ),
    noGallery: px.bool,
    onMainImageClick: px.func,
    onSelectImage: px.func,
    showGalleryMobile: px.bool,
    slidesToShow: px.number,
    useCarousel: px.oneOf([true, false, 'react', 'slick', 'react-paged']),
    useCarouselMobile: px.oneOf([true, false, 'react', 'slick', 'react-paged']),
    useImageModal: px.bool,
    useModalCarousel: px.bool,
};
