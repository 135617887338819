import * as React from 'react';
import px from 'prop-types';
import { ItemDetailsCard } from '.';
import Modal from '../ui/Modal';

export default function ItemDetailsModal({ 
    closeModal, 
    showModal,
    selectedChild, 
    setShowModal 
}) {

    const closeClear = React.useCallback(() => {
        closeModal();
        setShowModal(false);
    }, [closeModal, setShowModal])

    const item = React.useMemo(() => showModal ? selectedChild : null, [selectedChild, showModal])

    return showModal ? (
        <Modal
            onClose={closeClear}
            modalSize='lg'
            body={<ItemDetailsCard itemData={item} />}
            showModal={showModal}
        />
    ) : null
}

ItemDetailsModal.propTypes = {
    closeModal: px.func,
    selectedChild: px.object,
    setShowModal: px.func,
    showModal: px.bool,
};