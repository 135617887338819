import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Translation } from 'Common/components/localization';
import { useTranslation } from 'Common/hooks';
import { Email } from 'Common/utils';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export default function AdvancedSubscribeForm({
    allowMultiple,
    bodyColor,
    bodyText,
    btnColor,
    btnTextColor,
    className,
    getMailchimpStatus,
    id = uuidv4(),
    mailchimpSMSRequest,
    privacyUrl,
    smsConsentText,
    subscribeToMailchimp,
    tags,
    termsUrl,
    titleColor,
    titleFontStyle,
    url,
}) {
    const idRef = useRef(id);
    const formRef = useRef(null);
    const frameRef = useRef(null);
    const submitLbl = useTranslation('Form.Advanced.Subscription.Submit');

    const [email, setEmail] = useState('');
    const [smsPhone, setSmsPhone] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthdayMonth, setBirthdayMonth] = useState('');
    const [birthdayDay, setBirthdayDay] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [smsPhoneAck, setSmsPhoneAck] = useState(false);
    const [listId, setListId] = useState('');
    const [hiddenFieldName, setHiddenFieldName] = useState('');
    const [complete, setComplete] = useState(false);
    const [submissionErrors, setSubmissionErrors] = useState([]);

    const isValidEmail = useMemo(() => Email.isValid(email), [email]);

    const hasEmailInputValue = email.trim() !== '';
    const hasPhoneInputValue = smsPhone.trim() !== '';
    const hasBirthdayMonthValue = birthdayMonth.trim() != '';
    const hasBirthdayDayValue = birthdayDay.trim() != '';
    const showForm = allowMultiple || !complete || submissionErrors.length > 0;

    const isValidPhone = useMemo(() => {
        if (!hasPhoneInputValue) {
            return false;
        }
        try {
            const parsedNumber = phoneUtil.parseAndKeepRawInput(smsPhone, 'US');

            return phoneUtil.isValidNumber(parsedNumber);
        } catch {
            return false;
        }
    }, [smsPhone, hasPhoneInputValue]);

    const validationErrors = useMemo(() => {
        const errors = [];

        if (hasEmailInputValue && !isValidEmail) {
            errors.push('Form.Advanced.Subscription.Email.Input.Error');
        }
        if (hasPhoneInputValue && !isValidPhone) {
            errors.push('Commerce.Account.PhoneNumberValidationMessage');
        }
        if (hasPhoneInputValue && !smsPhoneAck) {
            errors.push('Form.Advanced.Subscription.PhoneAck.Input.Error');
        }
        if (hasBirthdayDayValue && !hasBirthdayMonthValue) {
            errors.push('Form.Advanced.Subscription.Birthday.Input.Error');
        }
        return errors;
    }, [
        hasBirthdayDayValue,
        hasBirthdayMonthValue,
        hasEmailInputValue,
        hasPhoneInputValue,
        isValidEmail,
        isValidPhone,
        smsPhoneAck,
    ]);

    const isFormValid = hasEmailInputValue && validationErrors.length === 0;

    useEffect(() => {
        if (url) {
            const newUrl = new URL(url);
            const u = newUrl.searchParams.get('u');
            const idParam = newUrl.searchParams.get('id');

            setListId(idParam);

            if (u && idParam) {
                setHiddenFieldName(`b_${u}_${idParam}`);
            }
        }
    }, [url]);

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();

            if (isFormValid) {
                setComplete(false);
                setSubmissionErrors([]);

                if (!isValidPhone) {
                    await subscribeToMailchimp(
                        email,
                        listId,
                        firstName,
                        lastName,
                        birthdayMonth,
                        birthdayDay,
                        zipCode,
                        Object.values(tags)
                    );
                } else {
                    const formData = {
                        ...(isValidEmail && { EMAIL: email }),
                        FNAME: firstName,
                        LNAME: lastName,
                        'MMERGE5[month]': birthdayMonth,
                        'MMERGE5[day]': birthdayDay,
                        MMERGE5: zipCode,
                        tags: tags ? Object.keys(tags).join(',') : '',
                    };

                    const result = await mailchimpSMSRequest(formData, url, formRef, frameRef);

                    if (!result?.success || result?.error) {
                        setSubmissionErrors([result?.error || 'Form.Advanced.Subscription.Failure']);
                    } else {
                        setComplete(true);
                    }
                }
            } else {
                setSubmissionErrors(['Please fill out all required fields correctly.']);
            }
        },
        [
            birthdayDay,
            birthdayMonth,
            email,
            firstName,
            isFormValid,
            isValidEmail,
            isValidPhone,
            lastName,
            listId,
            mailchimpSMSRequest,
            subscribeToMailchimp,
            tags,
            url,
            zipCode,
        ]
    );

    useEffect(() => {
        const frame = document.createElement('iframe');

        frame.name = `frame_${idRef.current}`;
        frame.style.display = 'none';
        frameRef.current = frame;
        document.body.appendChild(frame);

        return () => {
            document.body.removeChild(frame);
        };
    }, []);

    useEffect(() => {
        if (getMailchimpStatus?.message) {
            if (!getMailchimpStatus?.success) {
                setSubmissionErrors([getMailchimpStatus.message]);
            } else {
                setComplete(true);
                setSubmissionErrors([]);
            }

            setTimeout(() => {
                if (getMailchimpStatus?.success) {
                    setComplete(false);
                } else {
                    setSubmissionErrors([]);
                }
            }, 5000);
        }
    }, [getMailchimpStatus]);

    return (
        showForm && (
            <div
                className={cx('AdvancedSubscribeForm container', `text__color-${bodyColor}`, className)}
                id={idRef.current}
            >
                <form ref={formRef} onSubmit={handleSubmit} className="AdvancedSubscribeForm__Form needs-validation">
                    <label
                        className={cx('AdvancedSubscribeForm__Form-title subheader', `text__color-${titleColor}`)}
                        style={{ '--title-font-size': titleFontStyle }}
                    >
                        <Translation id="Form.Advanced.Subscription.Title" />
                    </label>
                    <p className="AdvancedSubscribeForm__Form-body">{bodyText}</p>
                    <div className="AdvancedSubscribeForm__Form__Inputs">
                        <div className="AdvancedSubscribeForm__Form__Inputs-topSection">
                            <div className="AdvancedSubscribeForm__Form__Inputs-input">
                                <input
                                    type="text"
                                    name="FNAME"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    autoComplete="given-name"
                                    placeholder="First Name"
                                    aria-label="First Name"
                                />
                            </div>
                            <div className="AdvancedSubscribeForm__Form__Inputs-input">
                                <input
                                    type="text"
                                    name="LNAME"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    autoComplete="family-name"
                                    placeholder="Last Name"
                                    aria-label="Last Name"
                                />
                            </div>
                            <div className="AdvancedSubscribeForm__Form__Inputs-input">
                                <input
                                    type="email"
                                    name="EMAIL"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete="email"
                                    placeholder="Email"
                                    aria-label="Email"
                                    required
                                />
                            </div>
                            <div className="AdvancedSubscribeForm__Form__Inputs-input">
                                <input
                                    type="tel"
                                    name="SMSPHONE"
                                    value={smsPhone}
                                    onChange={(e) => setSmsPhone(e.target.value)}
                                    autoComplete="tel"
                                    placeholder="Phone Number"
                                    aria-label="Phone Number"
                                />
                            </div>
                        </div>
                        <div className="AdvancedSubscribeForm__Form__Inputs-bottomSection">
                            <label className="birthday-label" htmlFor="MMERGE5-month">
                                Birthday
                            </label>
                            <div className="birthday-container">
                                <span>
                                    <input
                                        type="number"
                                        min="1"
                                        max="12"
                                        name="MMERGE5[month]"
                                        value={birthdayMonth}
                                        onChange={(e) => setBirthdayMonth(e.target.value)}
                                        autoComplete="bday-month"
                                        placeholder="MM"
                                        aria-label="Birthday month"
                                    />
                                    <div className="birthday-slash">{'/'}</div>
                                    <input
                                        type="number"
                                        min="1"
                                        max="31"
                                        name="MMERGE5[day]"
                                        value={birthdayDay}
                                        onChange={(e) => setBirthdayDay(e.target.value)}
                                        autoComplete="bday-day"
                                        placeholder="DD"
                                        aria-label="Birthday date"
                                    />
                                </span>
                            </div>
                            <label className="zip-label" htmlFor="MMERGE3">
                                Zip Code
                            </label>
                            <span className="zip-container">
                                <input
                                    type="text"
                                    name="MMERGE3"
                                    id="MMERGE3"
                                    value={zipCode}
                                    onChange={(e) => setZipCode(e.target.value)}
                                    autoComplete="postal-code"
                                    placeholder="Zip Code"
                                    aria-label="zip code"
                                />
                            </span>
                        </div>
                        <div className="AdvancedSubscribeForm__Form__Inputs-smsConsent">
                            <div>
                                <input
                                    type="checkbox"
                                    name="mc-SMSPHONE-ack"
                                    checked={smsPhoneAck}
                                    onChange={(e) => setSmsPhoneAck(e.target.checked)}
                                    value="true"
                                    aria-label="SMS consent"
                                />
                            </div>
                            <div className={cx('consent-text')}>
                                {smsConsentText}
                                {'See'}
                                <a
                                    href={termsUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={cx(`text__color-${bodyColor}`)}
                                >
                                    <Translation id="Form.Advanced.Subscription.Terms" />
                                </a>
                                {' and'}
                                <a
                                    href={privacyUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={cx(`text__color-${bodyColor}`)}
                                >
                                    <Translation id="Form.Advanced.Subscription.Privacy" />
                                </a>
                            </div>
                        </div>
                        {/* Include the hidden field with the constructed name for Mailchimp */}
                        {hiddenFieldName && <input type="hidden" name={hiddenFieldName} value="" />}
                        {tags && <input type="hidden" name="tags" value={Object.keys(tags).join(',')} />}
                    </div>
                    <div className="AdvancedSubscribeForm__Form__Submit">
                        <button
                            type="submit"
                            name="subscribe"
                            className={cx('btn', `btn-${btnColor}`, `text__color-${btnTextColor}`)}
                            disabled={!isFormValid}
                            aria-label={submitLbl}
                        >
                            <Translation id="Form.Advanced.Subscription.Submit" />
                        </button>
                    </div>
                    <div className="alert-box">
                        {complete && !submissionErrors.length && (
                            <div className={cx('alert alert-success', `text__color-${bodyColor}`)}>
                                <Translation id="Form.Advanced.Subscription.Success" />
                            </div>
                        )}
                        {(validationErrors.length > 0 || submissionErrors.length > 0) && (
                            <ul className={cx('alert alert-danger', `text__color-${bodyColor}`)}>
                                {validationErrors.map((err, idx) => (
                                    <li key={`validation-error-${idx}`}>
                                        <Translation id={err} />
                                    </li>
                                ))}
                                {submissionErrors.map((err, idx) => (
                                    <li key={`submission-error-${idx}`}>{err}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                </form>
            </div>
        )
    );
}

AdvancedSubscribeForm.propTypes = {
    allowMultiple: px.bool,
    bodyColor: px.string,
    bodyText: px.string,
    btnColor: px.string,
    btnTextColor: px.string,
    className: px.string,
    getMailchimpStatus: px.object,
    id: px.string,
    mailchimpSMSRequest: px.func,
    privacyUrl: px.string,
    smsConsentText: px.string,
    subscribeToMailchimp: px.func,
    tags: px.object,
    termsUrl: px.string,
    titleColor: px.string,
    titleFontStyle: px.string,
    url: px.string,
};
