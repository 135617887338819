import React, { useState } from 'react';
import px from 'prop-types';
import cx from 'classnames';
import useWindowResize from 'Common/hooks/useWindowResize';

export default function ResultsGrid({ className, style, children, gap = 0, expandedResultIndex, expandedChildren }) {
    const containerRef = React.useRef(null);

    const [resultsPerRow, setResultsPerRow] = useState(1);

    const rowInsertionIndex = Math.floor(expandedResultIndex / resultsPerRow) + 1;

    const recomputeResultsPerRow = React.useCallback(() => {
        const containerEl = containerRef.current;

        if (!containerEl) return;

        const firstResultEl = containerEl.childNodes[0];

        if (!firstResultEl) return;

        const resultRect = firstResultEl.getBoundingClientRect();
        const containerRect = containerEl.getBoundingClientRect();

        if (!resultRect?.width || !containerRect?.width) return;

        // Convert "gap" to pixels:
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

        // Each "gap" unit is 0.5rem based on spacing() func in _functions.scss
        // so gap=2 => 1rem => e.g. 16px
        const gapPx = gap * 0.5 * rootFontSize;

        const resultWidth = resultRect.width;
        const containerWidth = containerRect.width;

        // Solve for the maximum integer n such that:
        //   n * resultWidth + (n - 1) * gapPx <= containerWidth
        // => n = floor((containerWidth + gapPx) / (resultWidth + gapPx))
        const newResultsPerRow = Math.floor((containerWidth + gapPx) / (resultWidth + gapPx));

        setResultsPerRow(newResultsPerRow);
    }, [gap]);

    useWindowResize(recomputeResultsPerRow, [recomputeResultsPerRow]);
    React.useEffect(recomputeResultsPerRow, [children, expandedResultIndex, expandedChildren, recomputeResultsPerRow]);

    return (
        <div ref={containerRef} style={style} className={cx(className, 'ResultsGrid', `gap--${gap}`)}>
            {React.Children.map(children, (result, i) => (
                <div key={`ResultsGrid__result-${i}`} className="ResultsGrid__result">
                    {result}
                </div>
            ))}
            {expandedChildren ? (
                <div key="expansion" className="ResultsGrid__expansionCell" style={{ gridRow: rowInsertionIndex + 1 }}>
                    <div className="ResultsGrid__expansion">{expandedChildren}</div>
                </div>
            ) : null}
        </div>
    );
}

ResultsGrid.propTypes = {
    style: px.object,
    className: px.string,
    children: px.arrayOf(px.node),
    expandedResultIndex: px.number,
    expandedChildren: px.node,
    gap: px.number,
};
